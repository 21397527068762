<template>
  <div>
    <v-img
      alt="Epic Receptionists Logo"
      :src="logo"
      class="mr-3 hidden-xs-only"
      contain
      v-bind="$attrs"
      max-width="220px"
      min-width="200px"
      width="100%"
    />

    <!-- smaller logo on mobile -->
    <v-img
      alt="Epic Receptionists Logo"
      :src="logo"
      class="hidden-sm-and-up"
      contain
      v-bind="$attrs"
      min-width="200px"
      max-width="180"
      width="100%"
    />
  </div>
</template>

<script>
import LogoHorizontalDarkMode from '@/assets/logos/logo-horizontal-light.svg'
import LogoHorizontalLightMode from '@/assets/logos/logo-horizontal-dark.svg'

import LogoVerticalDarkMode from '@/assets/logos/logo-vertical-light.svg'
import LogoVerticalLightMode from '@/assets/logos/logo-vertical-dark.svg'

export default {
  props: {
    forceDark: {
      type: Boolean,
      default: false
    },

    vertical: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    logo() {
      const isDarkMode = this.$vuetify.theme.isDark || this.forceDark
      let logo = LogoHorizontalLightMode

      if (this.vertical) {
        logo = LogoVerticalLightMode
        if (isDarkMode) {
          logo = LogoVerticalDarkMode
        }
      } else {
        logo = LogoHorizontalLightMode
        if (isDarkMode) {
          logo = LogoHorizontalDarkMode
        }
      }

      return logo
    }
  }
}
</script>
