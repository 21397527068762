<template>
  <div class="hero-mini d-flex justify-center align-center flex-column" :class="{ dark: $vuetify.theme.isDark }">
    <h1 class="text-h3 text-center">
      {{ pageTitle }}
    </h1>
    <span class="text-caption text-center">
      {{ caption }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    pageTitle: {
      type: String,
      required: true
    },

    caption: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '@/assets/styles.scss';

.hero-mini {
  color: white;
  width: 100%;
  height: 200px;
  @include bg-gradient;

  &.dark {
    @include bg-gradient-dark;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .hero-mini {
    height: 160px;
  }
}
</style>
