<template>
  <div>
    <v-app-bar id="home-app-bar" app :color="isIndexPage ? forcedBgColor : 'background'" elevate-on-scroll height="80">
      <nuxt-link to="/">
        <logo-img :force-dark="isIndexPage" min-height="80px" />
        <span class="d-sr-only">Epic Receptionists, home page link</span>
      </nuxt-link>

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          :background-color="isIndexPage ? forcedBgColor : 'background'"
          color="secondary"
          optional
          :dark="isIndexPage"
        >
          <v-tab
            v-for="(navItem, index) in items"
            v-show="!navItem.isHidden"
            :key="index"
            nuxt
            link
            :to="{ name: navItem.pageName }"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            <v-btn
              v-if="navItem.highlight"
              color="secondary"
              class="black--text font-weight-bold"
              :title="navItem.displayName"
              :aria-label="navItem.displayName"
            >
              {{ navItem.displayName }}
            </v-btn>
            <span v-else>
              {{ navItem.displayName }}
            </span>
          </v-tab>
          <v-tab> </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        :dark="isIndexPage"
        class="hidden-md-and-up mr-4"
        aria-label="mobile-navigation-menu"
        @click="drawer = !drawer"
      >
        <v-icon large>{{ iconMenu }}</v-icon>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <nav-mobile v-model="drawer" right :items="items" />
  </div>
</template>

<script>
import { mdiMenu } from '@mdi/js'
import LogoImg from '@/components/Logo'
import NavMobile from '@/components/NavMobile'

export default {
  name: 'AppBar',

  components: {
    LogoImg,
    NavMobile
  },

  data: () => ({
    iconMenu: mdiMenu,
    drawer: null,
    items: [
      {
        displayName: 'Home',
        pageName: 'index',
        isHidden: true
      },
      {
        displayName: 'Features',
        pageName: 'features'
      },
      {
        displayName: 'Pricing',
        pageName: 'pricing'
      },
      {
        displayName: 'FAQs',
        pageName: 'faqs'
      },
      {
        displayName: 'About Us',
        pageName: 'about-us'
      },
      {
        displayName: 'Contact Us',
        pageName: 'contact-us'
      },
      {
        displayName: 'Blog',
        pageName: 'blog'
      },
      {
        displayName: 'Sign Up',
        pageName: 'sign-up',
        highlight: true
      }
    ]
  }),

  computed: {
    isIndexPage() {
      return this.$route.name === 'index'
    },

    forcedBgColor() {
      return this.isIndexPage ? '#000052' : '#ffffff'
    }
  }
}
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
