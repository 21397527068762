<template>
  <v-navigation-drawer
    color="transparent"
    fixed
    height="100vh"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list color="background" xshaped height="100%">
      <v-list-item
        v-for="navItem in items"
        :key="navItem.pageName"
        :to="{ name: navItem.pageName }"
        :exact="navItem.displayName === 'Home'"
        :target="navItem.highlight ? '_blank' : ''"
        color="secondary"
      >
        <v-list-item-content>
          <v-list-item-title>
            <v-btn v-if="navItem.highlight" block color="accent" class="white--text font-weight-black">
              {{ navItem.displayName }}
            </v-btn>
            <span v-else>
              {{ navItem.displayName }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavMobile',

  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
