<template>
  <base-content-page :page-title="pageTitle" :caption="pageDescription">
    <div class="text-center">
      <v-btn nuxt link x-large color="accent" to="/">Return to homepage</v-btn>
      <v-img
        max-width="600px"
        class="mx-auto mb-5 mt-5"
        contain
        min-height="400px"
        alt="Help, there has been an error"
        :src="require('@/assets/illustrations-bg-light/Asset 13.svg')"
      />

      <p>
        Error details:
        <br />
        <code>
          {{ error }}
        </code>
      </p>
    </div>
  </base-content-page>
</template>

<script>
import BaseContentPage from '@/components/BaseContentPage'

export default {
  components: {
    BaseContentPage
  },

  props: {
    error: {
      type: Object,
      default: null
    }
  },

  computed: {
    pageTitle() {
      return this.error.statusCode === 404 ? 'Page Not Found' : 'Unexpected Error'
    },

    pageDescription() {
      return this.error.statusCode === 404 ? 'The page you are looking for was not found' : this.error.message
    }
  },

  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription
        }
      ]
    }
  }
}
</script>
