<template>
  <v-app>
    <app-bar />

    <v-main>
      <v-fade-transition mode="out-in">
        <nuxt />
      </v-fade-transition>
    </v-main>

    <home-footer />
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar'
export default {
  name: 'HomeLayout',

  components: {
    AppBar,
    HomeFooter: () => import('@/components/Footer')
  }
}
</script>
