<template>
  <v-app style="background: black">
    <v-app-bar fixed app height="80">
      <nuxt-link to="/">
        <logo-img />
      </nuxt-link>
      <v-spacer />
      <v-toolbar-items class="d-sm-none d-md-flex d-none d-sm-flex">
        <v-btn text nuxt disabled> Home </v-btn>
        <v-btn text nuxt disabled> About Us </v-btn>
        <v-btn text nuxt disabled> Services </v-btn>
        <v-btn text nuxt disabled> Pricing </v-btn>
        <v-btn text nuxt disabled> Blog </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <!-- <v-container> -->
      <nuxt />
      <!-- </v-container> -->
    </v-main>
    <v-footer app>
      <span class="caption d-none d-sm-flex">Epic Receptionists, LLC</span>
      <a class="ml-2 d-none d-sm-flex" href="http://instagram.com/epic.receptionists" target="_blank" rel="noopener">
        <span class="d-sr-only">Instagram</span>
        <v-icon small>{{ iconInstagram }}</v-icon>
      </a>
      <a class="ml-2 d-none d-sm-flex" href="https://www.facebook.com/EpicReceptionists" target="_blank" rel="noopener">
        <span class="d-sr-only">Facebook</span>
        <v-icon small>{{ iconFb }}</v-icon>
      </a>
      <v-spacer class="d-none d-sm-flex" />
      <span class="caption">
        Made with <v-icon color="red" small>{{ iconHeart }}</v-icon> Epic Receptionists Is A Black Owned Company
      </span>
      <v-divider vertical class="mx-3 d-none d-sm-flex" />
      <span class="caption">
        <nuxt-link :to="{ name: 'privacy' }"> Privacy Policy</nuxt-link>
      </span>
      <v-divider vertical class="mx-3" />
      <span class="caption">
        <nuxt-link :to="{ name: 'terms-of-service' }">ToS</nuxt-link>
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import { mdiPhoneDial, mdiHeart, mdiInstagram, mdiFacebook } from '@mdi/js'
import LogoImg from '@/components/Logo'

export default {
  components: {
    LogoImg
  },

  data() {
    return {
      iconPhone: mdiPhoneDial,
      iconHeart: mdiHeart,
      iconInstagram: mdiInstagram,
      iconFb: mdiFacebook,
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Welcome',
          to: '/'
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Inspire',
          to: '/inspire'
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Epic Receptionists'
    }
  },

  created() {
    this.$vuetify.theme.dark = true
  }
}
</script>

<style scoped lang="scss">
a {
  color: white !important;
}
.title-link {
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
}
</style>
